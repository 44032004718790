import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import AccountPage from "../../components/layout/AccountPage"

import { postData } from "../../services/server"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faTag } from "@fortawesome/free-solid-svg-icons";

const Coupons = styled.table`
    width: 100%;

    th {
        padding: 5px;
    }
    td {
        padding: 8px;
        background-color: white;
    }
    td.Active {
        color: green;
    }
`

const AccountCouponsPage = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(
    () => {
        setLoading(true);

        postData("/api/coupons/foruser", {},
            function(result) {
                setCoupons(result);
            },
            function(error,status) {
                // if (status == 401 || status == 403)
                //     navigate("/account")
                // else
                    setError(error);
            },
            function() {
                setLoading(false);
            }
        );
      },
    []
  );

  return (
    <AccountPage 
        page="coupons" 
		breadcrumb={[
			{url: "/", title: "Home"},
			{url: null, title: "Coupons"}
		]}
        secure="registered" 
        loading={loading} 
        error={error}
    >
      { coupons.length ? 
            <Coupons>
                <thead>
                    <tr>
                        <th>Coupon Code</th>
                        <th>Type</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                {coupons.map(coupon => {
                    const status = coupon.status == "Partner" ? "Active" : coupon.expired_ind == 1 ? "Expired" : coupon.status;

                    return (
                        <tr key={coupon.id}>
                            <td className={status}>
                                <FontAwesomeSvgIcon icon={faTag} />{' '}
                                {coupon.code}
                            </td>
                            <td>
                                {coupon.type}
                                {(coupon.activation_info && coupon.activation_info.length > 0) && 
                                    <div>
                                        <small style={{color:"red"}}>{coupon.activation_info}</small>
                                    </div>
                                }
                            </td>
                            <td>
                                {status}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Coupons>
        :
            <p>No coupons found.</p>
      }
    </AccountPage>
  )
}

export default AccountCouponsPage
